import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../components';
import pageHeroData from '../../../data/pages/patterns.yml';
import formPreviewData from '../../../data/previews/forms-patterns.yml';
import styles from './styles/forms.module.scss';

const FormModifier = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.FormModifier;
    return <Component {...props} />;
  },
});

const Input = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Input;
    return <Component {...props} />;
  },
});

const Select = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Select;
    return <Component {...props} />;
  },
});

const Subhead = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Subhead;
    return <Component {...props} />;
  },
});

const Textarea = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Textarea;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedElement: 'title',
      selectedType: 'add',
      selectedState: 'default',
    };
  }

  handleElementChange(value) {
    this.setState({
      selectedElement: value,
    });
  }

  handleTypeChange(value) {
    this.setState({
      selectedType: value,
    });
  }

  handleStateChange(value) {
    this.setState({
      selectedState: value,
    });
  }

  render() {
    return (
      <PageWithSubNav pageType="design" title="Forms" subnav="patterns">
        <PageHero heroData={pageHeroData} tierTwo="Forms" hideHeader />
        <PageNavigation
          links={['Elements', 'Type', 'State', 'Usage', 'Microcopy']}
        />

        <Section title="Elements">
          <Paragraph>
            The following guidelines reference many different parts of the form.
            Take a quick look at what each one is and where it sits.
          </Paragraph>
          <ComponentPreview
            name="ElementsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={formPreviewData.elements}
            onChange={this.handleElementChange.bind(this)}>
            <div
              className={
                styles.elementsWrapper + ' ' + this.state.selectedElement
              }>
              <Subhead level="default" className="uni-margin--one--bottom">
                Personal Information
              </Subhead>
              <Input
                label="Name"
                type="text"
                isRequired
                placeholder="First and last"
                helpText="No initials"
              />
              <Input
                label="Email"
                type="email"
                isRequired
                placeholder="blank@test.com"
                helpText="We'll use this for your login"
              />
            </div>
          </ComponentPreview>
        </Section>
        <Section title="Type">
          <Paragraph>
            Of all the forms in Hudl, we found four common cases. Each has its
            own musts and maybes.
          </Paragraph>
          <ComponentPreview
            name="TypesContainer"
            layout="halfWidth"
            hideEnvironmentToggle
            previewData={formPreviewData.types}
            onChange={this.handleTypeChange.bind(this)}>
            {this.state.selectedType === 'add' && (
              <FormModifier density="compact">
                <Input type="text" label="Name" defaultValue="Peter Kavinsky" />
                <Input
                  type="email"
                  label="Email"
                  defaultValue="laxlyfe@internet.com"
                />
                <Select
                  label="Role"
                  defaultValue={{ label: 'Athlete', value: 'athlete' }}
                  options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Coach', value: 'coach' },
                    { label: 'Athlete', value: 'athlete' },
                  ]}
                />
                <Select
                  label="Graduation year"
                  defaultValue={{ label: '2020', value: '2020' }}
                  options={[
                    { label: '2019', value: '2019' },
                    { label: '2020', value: '2020' },
                    { label: '2021', value: '2021' },
                  ]}
                />
              </FormModifier>
            )}
            {this.state.selectedType === 'edit' && (
              <FormModifier density="compact">
                <Input
                  type="text"
                  label="Name"
                  defaultValue="Peter Covey"
                  autoFocus
                />
                <Input
                  type="email"
                  label="Email"
                  defaultValue="laxlyfe@internet.com"
                  isDisabled
                />
                <Select
                  label="Role"
                  defaultValue={{ label: 'Athlete', value: 'athlete' }}
                  options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Coach', value: 'coach' },
                    { label: 'Athlete', value: 'athlete' },
                  ]}
                />
                <Select
                  label="Graduation year"
                  defaultValue={{ label: '2020', value: '2020' }}
                  options={[
                    { label: '2019', value: '2019' },
                    { label: '2020', value: '2020' },
                    { label: '2021', value: '2021' },
                  ]}
                />
              </FormModifier>
            )}
            {this.state.selectedType === 'submit' && (
              <FormModifier density="compact">
                <Select
                  label="Invoice"
                  defaultValue={{
                    label: "Adler High boys' varsity lacrosse",
                    value: 'lacrosse',
                  }}
                  options={[
                    {
                      label: "Adler High boys' varsity lacrosse",
                      value: 'lacrosse',
                    },
                    {
                      label: "Adler High boys' varsity football",
                      value: 'football',
                    },
                  ]}
                />
                <Input
                  type="text"
                  label="Cardholder Name"
                  defaultValue="Josh Sanderson"
                />
                <Input
                  type="email"
                  label="Email"
                  defaultValue="letterwinner5@internet.com"
                />
                <Input
                  type="number"
                  label="Card Number"
                  defaultValue="1234567890123456"
                />
              </FormModifier>
            )}
            {this.state.selectedType === 'share' && (
              <FormModifier density="compact">
                <Select
                  label="Playlist"
                  defaultValue={{
                    label: 'Adler vs. Bayside assists',
                    value: 'bayside',
                  }}
                  options={[
                    { label: 'Adler vs. Bayside assists', value: 'bayside' },
                    {
                      label: 'Adler vs. Courtenay assists',
                      value: 'courtenay',
                    },
                  ]}
                />
                <Select
                  isSearchable
                  isMulti
                  label="Recipients"
                  defaultValue={[
                    { label: 'Peter Kavinsky', value: 'peter' },
                    { label: 'Lucas', value: 'lucas' },
                  ]}
                  options={[
                    { label: 'Peter Kavinsky', value: 'peter' },
                    { label: 'Josh Sanderson', value: 'josh' },
                    { label: 'Kenny', value: 'kenny' },
                    { label: 'Lucas', value: 'lucas' },
                  ]}
                />
                <Textarea
                  label="Notes"
                  defaultValue="Check these clips and report back before ski trip next week."
                  minHeight="125px"
                />
              </FormModifier>
            )}
          </ComponentPreview>
        </Section>

        <Section title="State">
          <ComponentPreview
            name="StatesContainer"
            layout="halfWidth"
            hideEnvironmentToggle
            previewData={formPreviewData.states}
            onChange={this.handleStateChange.bind(this)}>
            {this.state.selectedState === 'default' && (
              <Input
                type="email"
                label="Email"
                defaultValue="letterwinner5@internet.com"
              />
            )}
            {this.state.selectedState === 'disabled' && (
              <Input
                type="email"
                label="Email"
                defaultValue="letterwinner5@internet.com"
                isDisabled
              />
            )}
            {this.state.selectedState === 'error' && (
              <Input
                type="email"
                label="Email"
                defaultValue="letterwinner5@internet"
                hasError
                helpText="Invalid address."
              />
            )}
          </ComponentPreview>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Multiple Fields</SectionSubhead>
          <Paragraph>
            A true form is always two or more fields. If all you need is one
            field’s worth of information, add it to an existing (related)
            container.
          </Paragraph>
          <DontDo
            dontText="chop up the workflow with isolated fields."
            doText="display all fields together for one cohesive form."
            imgFilename="forms-multiple-fields-next"
          />
          <Paragraph>
            In the rare case separate panes are preferred, tie the fields
            together by communicating progress. Just be sure the user can go
            'back' if necessary. If they can’t, don't split the form at all.
          </Paragraph>
          <DontDo
            dontText="force a user through a series of steps."
            doText="allow them to backtrack and progress however they'd like."
            imgFilename="forms-multiple-fields-progress"
          />
          <Paragraph>
            Stick with 'next' for forward progress. Avoid ‘save’ unless they can
            abandon the form and finish later.
          </Paragraph>
          <SectionSubhead>Required</SectionSubhead>
          <Paragraph>
            For a longer form (5+ fields), use an asterisk to denote required
            information.
          </Paragraph>
          <Paragraph>
            If the <em>entire</em> form is required, use a note to save everyone
            from the chaos of a million asterisks.
          </Paragraph>
          <DontDo
            dontText="overwhelm or distract by marking every field."
            doText="clearly state upfront that all information is required."
            imgFilename="forms-required"
          />
          <SectionSubhead>Layout</SectionSubhead>
          <Paragraph>
            Prioritize fields the way you'd prioritize the info itself. If you
            get stuck, think about how you'd ask the questions out loud. What
            would kick off the <em>interview</em>?
          </Paragraph>
          <DontDo
            dontText="randomly add fields with no clear order."
            doText="organize the form as its own conversation."
            imgFilename="forms-layout"
          />
          <Paragraph>
            For forms with a mix of required and optional, put required fields
            at the top (ideally all together, but if that doesn’t work
            logically, don’t force it).
          </Paragraph>
          <Paragraph>
            “Obvious” order applies to actual steps, as well as info with a
            common display, like addresses. (In the rare case we ask for{' '}
            <strong>country</strong>, put it last—after city, state and zip
            code. It saves us from disrupting the order by adding or removing
            when necessary.)
          </Paragraph>
          <Paragraph>
            For larger forms, take full advantage of the{' '}
            <Link href="/components/themes/divider/design">
              divider component
            </Link>
            . Think personal info, contact info, shipping, billing, etc.
          </Paragraph>
          <SectionSubhead>Recipients</SectionSubhead>
          <Paragraph>
            When using a form to share, don't use 'To:' anywhere.
          </Paragraph>
          <Paragraph>
            Sending a message could be <em>to</em> someone, but when sharing
            video and data, <em>with</em> is more natural. A{' '}
            <strong>recipients</strong> field fits both.
          </Paragraph>

          <DontDo
            dontText="use 'To:' as a field label or placeholder."
            doText="provide a spot for the user to list recipients."
            imgFilename="forms-recipients"
          />
          <Paragraph>
            Share recipients are usually chosen from a multi-select, so be sure
            to check those{' '}
            <Link href="/components/forms/select/design#Microcopy">
              placeholder guidelines
            </Link>
            .
          </Paragraph>
          <Paragraph>
            For messages, the placeholder should help the user enter recipients
            (e.g.,
            <em>Separate multiple emails with a comma</em>
            ).
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Titles aren’t required, but they help the user understand what
            completing a form will do.
          </Paragraph>
          <Paragraph>
            Keep it brief, 4-5 words max. It shouldn’t be a complete thought,
            just the purpose of the form itself.
          </Paragraph>

          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            Check{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>

          <SectionSubhead>Placeholder</SectionSubhead>
          <Paragraph>
            Placeholders are suggested but not always necessary. They shouldn’t
            repeat the label outright or insert the label into a CTA (e.g.,{' '}
            <em>Enter your email address</em>
            ).
          </Paragraph>
          <Paragraph>
            Use one to provide an example or preferred format, like a date or
            phone number.
          </Paragraph>
          <DontDo
            dontText="repeat the label for the sake of having a placeholder."
            doText="provide more instruction with a sample entry."
            imgFilename="textinput-placeholder"
          />
          <Paragraph>
            Placeholders should follow{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product">
              non-label text guidelines
            </Link>{' '}
            <em>without</em> punctuation.
          </Paragraph>

          <SectionSubhead>Help Text</SectionSubhead>
          <Paragraph>
            Help text can provide details not included in the label or
            placeholder, like certain criteria to meet for a valid submission.
          </Paragraph>
          <DontDo
            dontText="use the help text to provide a sample entry."
            doText="provide any additional context for that field."
            imgFilename="textinput-helptext"
          />
          <Paragraph>
            In the case of an error, update the help text to explain why an
            error occurred and how it’s fixed.
          </Paragraph>

          <SectionSubhead>CTAs</SectionSubhead>
          <Paragraph>
            For any add, edit or create workflow, use <em>save</em> to indicate
            the new information will be stored. Include the object for{' '}
            <Link href="https://www.hudl.com/design/writing/writing-for/components/cta-buttons-links">
              congruence
            </Link>
            .
          </Paragraph>
          <DontDo
            dontText="be vague with a form's CTA."
            doText="clearly communicate what the button will accomplish."
            imgFilename="forms-cta-save"
          />
          <Paragraph>
            For <em>share</em> and <em>send</em>, clearly call out which thing
            they’re doing. The same goes for <em>submit</em>
            —they shouldn’t expect anything in return.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
